import React from 'react';
import {useState} from 'react';
import {useRef} from 'react';
import {useEffect} from 'react';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'
import GetApp from '@material-ui/icons/GetApp'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import MapChart from "./MapChart";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        ...theme.typography.body1
    },
    paper: {
        overflow: "auto"
    },
    avatar: {
        position: "relative",
        width: theme.spacing(15), 
        height: theme.spacing(15),
    },
    card: {
        marginBottom: 10
    },
    mail: {
        verticalAlign: "middle"
    },
    linkedin: {
        verticalAlign: "middle"
    }
}));

export default function Resume(props) {
    const [selectedPin, selectPin] = useState("none")
    const ambryRef = useRef(null);
    const epicRef = useRef(null);
    const iqviaRef = useRef(null);
    const languageRef = useRef(null);
    const classes=useStyles();

    const bullet = <span><br/>•</span>;

    const aboutMe = <Paper><Box p={2} pt={0.5}>
    <Button onClick={openResume} style={{position:"relative", float: "right"}}>
        <GetApp /> Download as PDF
    </Button>
    <h2>About me</h2>
    <Card className={classes.card}>
        <CardContent>
            <Grid container>
                <Grid item>
                    <Box pr={2}>
                        <Avatar alt="Lukas Lyon" src="https://s3-us-west-1.amazonaws.com/lukaslyon.com/resources/LukasInSarajevo.jpg" className={classes.avatar}/>
                    </Box>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                    <Box pl={2}>
                        <h3>Lukas Lyon</h3>
                        <MailOutlineIcon className={classes.mail}/> <a href="lukasalexlyon@gmail.com" style={{verticalAlign: "middle"}}>lukasalexlyon@gmail.com</a> <br/>
                        <LinkedInIcon className={classes.mail}/> <a href="https://www.linkedin.com/in/lukas-lyon/" style={{verticalAlign: "middle"}}>Connect</a>
                    </Box>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
    I am a driven young professional with experience in a variety of high-impact fields. I have a passion for learning and believe
    that every problem has a modern solution. To that end, I am constantly updating my skillset by trying new things, be it a new
    programming language, a new foreign language, or typeface design. In the workplace, I have a reputation as a critical thinker
    and assertive contributor in all projects I work on. 
    </Box></Paper>;

    const ambryExperience = <Paper><Box p={2} pt={0.5} ref={ambryRef}>
        <h2>Experience</h2>
        <img src="https://www.ambrygen.com/bundles/cache/our_logo_png/assets/img/logo/Ambry_KM_logo_rgb_1217.png?v-2019-05-29" alt="Ambry" width="30%"/>
        <br/><b>Implementation Manager – <a href="https://www.ambrygen.com/" rel="noopener noreferrer" target="_blank">Ambry Genetics</a></b> <br/>
        Aliso Viejo, CA (remote). <i>April 2020 – present</i> <br/>
        {bullet} Work with Enterprise Solutions team to optimize installation and onboarding processes
        {bullet} Manage ongoing client projects and maintain client relationships
        {bullet} Work closely with product team to optimize product and roll out new features
    </Box></Paper>;

    const epicExperience = <Paper><Box p={2} ref={epicRef}>
        <img src="https://seekvectorlogo.com/wp-content/uploads/2019/04/epic-systems-corporation-vector-logo.png" alt="Epic" width="25%"/>
        <br/><b>Technical Solutions Engineer – <a href="https://www.epic.com/" rel="noopener noreferrer" target="_blank">Epic Systems</a></b> <br/>
        Madison, WI. <i>August 2019 – April 2020 </i> <br />
        {bullet} Leveraged application knowledge to manage customer teams through complex initiatives
        {bullet} Maintained meaningful customer relationships across healthcare organizations
        {bullet} Coordinated the monthly creation and release of a suite of hospital capacity management tools
    </Box></Paper>;

    const iqviaExperience =<Paper><Box p={2} ref={iqviaRef}>
        <img src="https://www.iqvia.com/-/media/iqvia/iqvia-logo-color.svg?mw=300&hash=CECD41EE60F67B3B27A5D7E6E7BABDFA" alt="IQVIA" width="30%"/>
        <br/><br/><b>Statistical Programming Intern – <a href="https://www.iqvia.com/" rel="noopener noreferrer" target="_blank">IQVIA</a></b> <br />
        Durham, NC. <i>May 2018 – March 2019 </i> <br />
        {bullet} Worked in teams to develop datasets and outputs (tables, listings, figures) for pharmaceutical clients using SAS 9.4
        {bullet} Became familiar with clinical trial protocols and analysis plans
        {bullet} Developed strategies for efficiently handling large datasets to meet client delivery timelines
    </Box></Paper>

    const libraryExperience = <Paper><Box p={2}>
        <img src="https://imageserv.lib.unc.edu/branding/UniversityLibraries_logo_white_h.png" alt="Davis" width="30%" style={{backgroundColor: "#4B9CD3" }}/> 
        <br/><b>Slavic & Eastern European Resources Center Student Assistant – <a href="https://www.unc.edu/" rel="noopener noreferrer" target="_blank">UNC-CH </a> </b> < br />
        Chapel Hill, NC. <i>August 2016 – May 2018 </i> <br />
        {bullet} Created primary bibliographic records for online catalog and payment records for accounting
        {bullet} Promoted to a supervisory role (usually reserved for graduate students)
    </Box></Paper>

    const education = <Paper><Box p={2} pt={0.5}>
        <h2>Education</h2>
        <img src="https://i.pinimg.com/originals/15/3a/cb/153acbc4b616698ca3f4ddd028dc8aa4.jpg" alt="UNC" width="40%" />
        <br/><b>The University of North Carolina at Chapel Hill</b> <br/>
        BSPH Biostatistics with a second major in Mathematics and a minor in Chemistry <br/>
        Graduation: May 2019 <br/>
        GPA: 3.90 (BIOS: 4.00, MATH: 3.90) <br />
        Honors: Phi Beta Kappa <br />
        Relevant Coursework: Multivariable Calculus, Discrete Math, Differential Equations, Linear Algebra, Real Analysis, Abstract
        Algebra, Topology, Statistical Modelling, Statistical Programming (R and SAS), Organic Chemistry, Genetics
    </Box></Paper>

    const skills = <Paper><Box p={2} pt={0.5} ref={languageRef}>
        <h2>Languages and Skills</h2>
        <b>Languages: </b> German (bilingual), Russian (fluent) <br/>
        <b>Computer Programming: </b>SAS, Python, R, JavaScript, React.js, LaTeX <br/>
    </Box></Paper>
    
    const resume = <>
        {aboutMe} <br/>
        {ambryExperience} <br/>
        {epicExperience} <br/>
        {iqviaExperience} <br/>
        {libraryExperience} <br />
        {education} <br/>
        {skills}
    </>

    function openResume(){
        window.open(
            'http://lukaslyon.com/Resume(Sep2020).pdf'
          );
    }

    function scrollToRef(ref){
        window.scrollTo({left: 0, top: ref.current.offsetTop - 50, behavior: "smooth"})
    }

    useEffect(() => {
        var ref;
        switch(selectedPin){
            case 'none': window.scrollTo({left: 0,top: 0, behavior: "smooth"}); break; 
            case 0: ref = languageRef; break;
            case 1: ref = iqviaRef; break;
            case 2: ref = languageRef; break;
            case 3: ref = languageRef; break;
            case 4: ref = epicRef; break;
            case 5: ref = ambryRef; break;
            default: ref = ambryRef; break;
        }

        if (selectedPin !== 'none'){
            scrollToRef(ref);
        }


    });

    return (
        <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
                <Box p={2}>{resume}</Box>
            </Grid>
            <Grid item sm={6} xs={12}>
                <Paper style={{position: "fixed", width: "48%"}} sm={6} xs={12}>
                    <Box p={2} style={{width: "95%"}}><MapChart selectedPin={selectedPin} selectPin={selectPin}/></Box>
                </Paper>
            </Grid>
        </Grid>
        </div>
    )
}