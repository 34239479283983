import React from "react";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      ...theme.typography.body1
    }
}));

export default function DistanceProject(props){
    const classes = useStyles();

    return(
        <Paper className={classes.root}>
            <Box p={3}>
                <h1>A New Kind of Map</h1>
                <p>
                    It recently occured to me that standard maps give us a useful overview of a geographic area but fail to represent
                    travel times. Modern maps services provide user interfaces that give us some insight into this by overlaying roads
                    with color to represent density of traffic. Enter: the newest (and least user-friendly) way to view geographic data. 
                    Using Google's Distance Matrix API, multidimensional scaling and a fantastic R package for manipulating meshes, I 
                    was able to generate a view of a small section of San Francisco as well as a larger section of the United states where 
                    physical distance between two points on the map is proportional to the time it would take to drive between those two points.
                </p>
                <p>
                    This proof of concept completely exhausted my free tier Google Maps API account; I welcome a more well-funded individual
                    to take this project further.
                </p>
                <div style={{margin: "0 auto"}}>
                    <img src="https://s3-us-west-1.amazonaws.com/lukaslyon.com/resources/map.JPG" alt="SF normal" width="50%"/>
                    <img src="https://s3-us-west-1.amazonaws.com/lukaslyon.com/resources/SF_Deformed_flipped.png" alt="SF warped" width="50%"/>
                    <br/>
                    <img src="https://s3-us-west-1.amazonaws.com/lukaslyon.com/resources/ammap.JPG" alt="Midwest normal" width="50%"/>
                    <img src="https://s3-us-west-1.amazonaws.com/lukaslyon.com/resources/Midwest_deformed%2Bflipped.png" alt="Midwest warped" width="50%"/>
                </div>
                <h2>Getting the data</h2>
                <p>
                    Getting the distance data proved to be more challenging than expected due to Google Maps Distance Matrix API's volume and 
                    content limits; obtaining a distance matrix with more than 10 origins and destinations is not allowed. Therefore, I pieced
                    together the data from appropriately sized chunks. Still, this required a large number of API calls and I was only able to
                    generate the two proofs of concept included in this writeup. To obtain the data, I used an R wrapper for the Google Maps API; 
                    you can find it <a href="https://cran.r-project.org/web/packages/mapsapi/mapsapi.pdf">here</a>.
                </p>
                <Card>
                    <CardContent>
                        <Typography color="textSecondary" classes={{fontSize: 14}}>
                            This R script obtains a distance matrix from the Google Maps API by repeatedly querying for smaller chunks of the larger
                            distance matrix, then pieces them together. You can adjust the latitude and longitude ranges as needed.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" href="https://s3-us-west-1.amazonaws.com/lukaslyon.com/Distance+Manifold.R">Get the Code</Button>
                    </CardActions>
                </Card>

                <h2>Processing the Data</h2>
                <p>
                    Multidimensional scaling is an eigenvector-based technique which seeks a (non-unique) set of points in Euclidean space which
                    satisfy (to the greatest extent possible) a given distance matrix. You can find more information <a href="https://en.wikipedia.org/wiki/Multidimensional_scaling">
                    here </a>. R has a great MDS function in the stats package; documentation can be found <a href="https://www.rdocumentation.org/packages/stats/versions/3.6.2/topics/cmdscale">
                    here</a>.
                </p>
                <p>
                    At this point, we have a set of points which represent a deformed grid. How can we reconstruct the map from these data points? 
                    This is where a fantastic R package called Morpho (documentation <a href="https://cran.r-project.org/web/packages/Morpho/Morpho.pdf">here</a>)
                    comes in handy. This package houses a number of useful functions for manipulating 2D and 3D meshes. We are particularly interested in 
                    computeTransform, which enables us to find the affine transformation matrix that maps one triangulation to another. Some image manipulation follows and
                    we have our result! It's quite grainy but there you go. 
                </p>
                <p>
                    What you take from these images is up to you. One piece of information that I found interesting is how much more the US is squeezed in the horizontal
                    direction than the vertical. Perhaps there are more interstate highways going West-East than North-South; perhaps those roads just have higher speed
                    limits. As the old saying goes, further research is needed. 
                </p>
                <Card>
                    <CardContent>
                        <Typography color="textSecondary" classes={{fontSize: 14}}>
                            This R script takes in a distance matrix, a matrix of points, and an undeformed map. The result is the deformed map that represents longer travel
                            times by larger physical distance. 
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" href="https://s3-us-west-1.amazonaws.com/lukaslyon.com/Distance_Transform.R">Get the Code</Button>
                    </CardActions>
                </Card>
            </Box>
        </Paper>
    )
}