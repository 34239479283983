import React from 'react';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth:270
    },
    title: {
        fontSize: 30
    },
    care: {
        height: 89,
        scale: 0.3
    },
    epic: {
        height: 150,
        scale: 0.3
    }
}));

export default function ProfessionalProjects(props){
    const classes=useStyles();

    return(
        <Grid container spacing={3}>
            <Grid item sm={4} width="80%">
                <Card className={classes.root}>
                    <CardContent>
                        <CardMedia
                            className={classes.care}
                            image="https://www.ambrygen.com/assets/img/logo/CARElogo_RGB.png"
                            title="CARE Program"
                        />
                        <Typography>
                        <br/>The CARE Program provides clinically actionable results to healthcare professionals to inform cancer screening and prevention, and effectively communicates this information to patients and their care teams.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" href="https://www.ambrygen.com/partners/care" target="_blank">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item sm={4} width="80%">
                <Card className={classes.root}>
                    <CardContent>
                        <CardMedia
                            className={classes.epic}
                            image="https://seekvectorlogo.com/wp-content/uploads/2019/04/epic-systems-corporation-vector-logo.png"
                            title="Epic Systems"
                                />
                        <Typography>
                        <br/> Epic's Admission/Discharge/Transfer (ADT) module is a system for tracking inpatient visits. It is u sed by organizations to track
                        inpatients from their arrival (admission), to their movements inside the hospital (transfer), to their departure
                        (discharge). 
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" href="https://www.epic.com/" target="_blank">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )

}