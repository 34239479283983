import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    flexGrow: 1,
    background: "#757575",
    position: "sticky"
  },
  indicator: {
      background: "#8eacbb",
      height: 5
  },
  label: {
      fontFamily: "Helvetica Neue"
  }
}));

export default function PrimaryTab(props) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

  var tabs = [];
  var panels = [];
  for (var i=0; i < props.labels.length; i++){
    tabs.push(<Tab label={props.labels[i]} {...a11yProps(i)} key={["mainTab", i].join()}/>)
    panels.push(<TabPanel value={props.value} index = {i} key={["mainTabPanel", i].join()}>
            {props.tabContents[i]}
        </TabPanel>)
  }


  return (
      <div>
      <AppBar className={classes.appBar}>
        <Tabs value={props.value} onChange={handleChange} aria-label="simple tabs example" classes={{indicator:classes.indicator}}>
            {tabs}
        </Tabs>
      </AppBar>
      {panels}
      </div>
  );
}