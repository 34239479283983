import React from "react";
import ReactDOM from "react-dom";
import {useState} from 'react';

// import "./styles.css";
import PrimaryTab from './components/PrimaryTab';
import SecondaryTab from './components/SecondaryTab';
import Resume from './components/Resume';
import ProfessionalProjects from './components/ProfessionalProjects';
import DistanceProject from './components/DistanceProject'
// import SimpleMenu from './components/SimpleMenu'

const tabs=["Professional", "Personal"];
const subTabs = [];
subTabs.push(
  <SecondaryTab labels={["Resume", "Projects"]} tabContents={[<Resume />, <ProfessionalProjects />]} key="profSubtabs" />
)
subTabs.push(
  <SecondaryTab labels={["Data Visualization", "Web Design"]} tabContents={[<DistanceProject />, "You're looking at my first React project!"]} key="perSubtabs"/>
)

function App() {
  const [primTabsValue, setPrimTabsValue] = useState(0);
  return (
    <PrimaryTab value={primTabsValue} setValue={setPrimTabsValue} labels={tabs} tabContents={subTabs} key="mainTabs"/>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);