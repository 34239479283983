import React from "react";
import {useRef} from 'react';
import { Map, Marker, Popup, TileLayer} from 'react-leaflet'

const markerInfo = [
  {
    id: 0,
    markerOffset: 0,
    name: "Munich, Germany",
    coordinates: [48.1351, 11.5820],
    content: <><b>Munich, Germany</b> <br/> <i>1996 - 2004</i> <br/> <div>I am natively fluent in German and am a dual citizen of Germany and the United States.</div></>
  },
  {
    id: 1,
    markerOffset:  0,
    name:  "Chapel Hill, NC",
    coordinates: [35.9132, -79.0558],
    content: <><b>Research Triangle, North Carolina</b> <br/> <i>2004 - 2014, 2015-2019</i> <br/> <div>After moving to the US, I attended the <a href="https://sph.unc.edu/">University of North Carolina at Chapel Hill</a>, where I majored in Mathematics and Biostatistics.</div></> 
  },
  {
    id: 2,
    markerOffset:  0,
    name: "Chișinău, Moldova",
    coordinates: [47.0105, 28.8638],
    content: <><b>Chisinau, Moldova</b> <br/> <i>Fall 2014</i> <br/><div>I spent a year in Eastern Europe taking intensive Russian language classes while living with a host family. This opportunity came through <a href="https://www.nsliforyouth.org/">NSLI-Y</a>, a State Department-funded initiative.</div></>
  },
  {
    id: 3,
    markerOffset:  0,
    name: "Narva, Estonia",
    coordinates: [59.3797, 28.1791],
    content: <><b>Narva, Estonia</b> <br/> <i>Fall 2014</i><br/><div>I spent a year in Eastern Europe taking intensive Russian language classes while living with a host family. This opportunity came through <a href="https://www.nsliforyouth.org/">NSLI-Y</a>, a State Department-funded initiative.</div></>
  },
  {
    id: 4,
    markerOffset:  0,
    name: "Madison, WI",
    coordinates: [43.0731, -89.4012],
    content: <><b>Madison, Wisconsin</b> <br/> <i>2019-2020</i><br/> <div>After graduating from college, I lived in Madison, WI while working at <a href="https://www.epic.com/">Epic Systems</a>. Much of my nuanced understanding of healthcare logistics comes from this experience.</div></>
  },
  {
    id: 5,
    markerOffset:  0,
    name: "San Francisco, CA",
    coordinates: [37.7749, -122.4194],
    content: <><b>San Francsisco, California</b> <br/> <i>2020 - present</i> <br/> <div>After beginning to work remotely at <a href="https://www.ambrygen.com/">Ambry Genetics</a> as an implementation manager, I moved to San Francisco, CA, where I am patiently waiting for the global pandemic to fade so I can fully enjoy this beautiful city!</div></>
  }
];

function MapChart(props) {

  const mapRef = useRef(null);

  function handleMarkerClick(marker) {
    mapRef.current.leafletElement.flyTo(marker.coordinates, 4);
    props.selectPin(marker.id)
  }

  function handleMapClick(){
    mapRef.current.leafletElement.flyTo([50, -40], 1.5)
    props.selectPin("none")
  }

  const markers = markerInfo.map((marker) =>
  <Marker position={marker.coordinates} key={["marker", marker.id].join()} onClick={() => {handleMarkerClick(marker)}}>
    <Popup>{marker.content}</Popup>
  </Marker>
);

  return(
      <Map center={[50, -40]} zoom={1.5} id="mapid" style={{height: "500px"}} useFlyTo={true} ref={mapRef} onClick={handleMapClick}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
      {markers}
      </Map>
  )
};

export default MapChart;